import React from "react";
import MUIDataTable from "mui-datatables";
import CustomToolbarSelect from "./CustomToolbarSelect";

const TableComponent = ({ title, columns, data, filter, download ,selectableRowsHideCheckboxes=true , onRowsSelect=()=>{}, rowsPerPage=10}) => {

  const options = {
    filterType: 'dropdown',
    download: download,
    filter: filter,
    print: false,
    viewColumns: false,
    search: true,
    selectableRowsHideCheckboxes: selectableRowsHideCheckboxes,
    selectableRows: 'multiple',
    onRowSelectionChange:onRowsSelect,
    customToolbarSelect: (selectedRows, displayData, setSelectedRows) => (
      <CustomToolbarSelect selectedRows={selectedRows} displayData={displayData} setSelectedRows={setSelectedRows} />
    ),
    rowsPerPage:rowsPerPage,
    // rowsPerPageOptions:[5,10,15,100]
  };

  return (
      <MUIDataTable
        data={data}
        columns={columns}
        options={options}
      />
  )
}

export default TableComponent;