import { Box, Grid } from "@mui/material";
import React from "react";
import SidebarContainer from "../Sidebar/SidebarContainer";
import HeaderContainer from "../Header/Header";
import FooterContainer from "../Footer/Footer";
import { Outlet } from "react-router-dom";
import { USER_ROLE } from "../../../core/Constant";
import LayoutStyle from "../../../assets/styles/layout/loggedLayoutStyle";

const Layout = ({ userRole }) => {
  return (
    <Box sx={{ ...LayoutStyle.mainContainer }}>
      <Box sx={{ ...LayoutStyle.sidebarWrap }}>
        <SidebarContainer
          type={
            localStorage.getItem("userType") == USER_ROLE.admin
              ? "admin"
              : "student"
          }
        />
      </Box>
      <Box sx={{ ...LayoutStyle.contentWrap }}>
        <HeaderContainer />
        <Outlet />
      </Box>
    </Box>
  );
};

export default Layout;
