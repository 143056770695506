import Repository from "./Repository";
import _ from "lodash";

//API CALL for Guest flow
class GuestRepository extends Repository {
  onGetAllQuizList = async (params) => {
    try {
      const data = await this.getData(`admin/quiz/fetch`, params);
      return _.get(data, `data`);
    } catch (error) {
      return { error };
    }
  };

  onCreateQuiz = async (params) => {
    try {
      const data = await this.postData("admin/quiz/create", params);
      return _.get(data, `data`);
    } catch (error) {
      return { error };
    }
  };
  onGetSingleQuizData = async (quizId) => {
    try {
      const data = await await this.getData(
        `admin/quiz/fetchById/${quizId}`,
        {}
      );
      return _.get(data, `data`);
    } catch (error) {
      return { error };
    }
  };

  onGetSingleQuestionAndAnswers = async (quizeId) => {
    try {
      const data = await await this.getData(
        `admin/question/fetchQnA/${quizeId}`,
        {}
      );
      return _.get(data, `data`);
    } catch (error) {
      return { error };
    }
  };

  onDeleteQuiz = async (quizId) => {
    try {
      const data = await this.deleteData(`admin/quiz/delete/${quizId}`, {});
      return _.get(data, `data`);
    } catch (error) {
      return { error };
    }
  };

  onUpdateQuizState = async (params) => {
    try {
      const data = await this.putData(`admin/quiz/activateDeactivate`, params);
      return _.get(data, `data`);
    } catch (error) {
      return { error };
    }
  };

  onGetCourseWeeksData = async (courseId) => {
    try {
      const data = await this.getData(
        `admin/quiz/weekDropdown/${courseId}`,
        {}
      );
      return _.get(data, `data`);
    } catch (error) {
      return { error };
    }
  };

  onGetQuizSubmissionHistory = async (params) => {
    try {
      const data = await this.getData(`admin/quiz/submissionList`, params);
      return _.get(data, `data`);
    } catch (error) {
      return { error };
    }
  };

  onCreateQuestion = async (params) => {
    try {
      const data = await this.uploadForm(
        "admin/question/questionsAnswers",
        params
      );
      return _.get(data, `data`);
    } catch (error) {
      return { error };
    }
  };

  onDeleteQuestion = async (questionId) => {
    try {
      const data = await this.deleteData(
        `admin/question/delete/${questionId}`,
        {}
      );
      return _.get(data, `data`);
    } catch (error) {
      return { error };
    }
  };

  addFeedbackAssessment = async (params) => {
    try {
      const data = await this.putData(`admin/quiz/addFeedback`, params);
      return _.get(data, `data`);
    } catch (error) {
      return { error };
    }
  };

  assessmentQuestionNReasonAdmin = async (params) => {
    try {
      const data = await this.postData(
        "admin/quiz/questionWiseAssessment",
        params
      );
      return _.get(data, `data`);
    } catch (error) {
      return { error };
    }
  };

  onDeleteAttempt = async (params) => {
    try {
      const data = await this.postData(`admin/quiz/deleteAttempt`, params);
      return _.get(data, `data`);
    } catch (error) {
      return { error };
    }
  };

  onUpdateQuiz = async (params) => {
    try {
      const data = await this.postData(`admin/quiz/update`, params);
      return _.get(data, `data`);
    } catch (error) {
      return { error };
    }
  };

  onUpdateQuestion = async (params) => {
    try {
      const data = await this.postData(`admin/question/update`, params);
      return _.get(data, `data`);
    } catch (error) {
      return { error };
    }
  };
}



export default new GuestRepository("quiz");
