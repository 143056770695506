import { Outlet, Navigate } from "react-router-dom";
import { USER_ROLE } from "../../../core/Constant";
import { useEffect, useState } from "react";
import { Actions } from "../../../core/modules/Actions";
import { connect } from "react-redux";

const StudentRoutes = ({getcourseProgress,courseprogress}) => {
    const [intialcourse,setInitialCourse] = useState([]);
    useEffect(()=>{
        getcourseProgress()
    },[])

    useEffect(()=>{
       setInitialCourse(courseprogress[0])
       localStorage.setItem('firstcourse', intialcourse?.course_id)
    },[courseprogress,intialcourse])



    let user = parseInt(localStorage.getItem('userType'));
    return (<>
    {user == USER_ROLE.student ? <Outlet/>: <Navigate to="*"/>}
    </>);
}

export default connect(
        (state) => ({
          courseprogress: state.home.get("courseprogress"),
        }),
        {
          getcourseProgress: Actions.home.getcourseProgress,
        }
)(StudentRoutes);