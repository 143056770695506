import { createAction } from '../../core/AppUtils';
import { CommonRepository } from '../../core/repository';

export const ModuleEvents = {
  LOADING_STARTED: 'LOADING_STARTED',
  LOADING_FINISHED: 'LOADING_FINISHED',
  COURSE_LIST: 'COURSE_LIST',
  NETWORK_ERROR:'NETWORK_ERROR',
  UPDATE_USER:'UPDATE_USER',
  COURSE_LIST:'COURSE_LIST',
  GET_ACTIVE_FLAG:'GET_ACTIVE_FLAG'
};

export default {
  networkError: createAction(ModuleEvents.NETWORK_ERROR, error => error),
  loadingStarted: createAction(ModuleEvents.LOADING_STARTED, action => action),
  loadingFinished: createAction(ModuleEvents.LOADING_FINISHED, action => action),
  getActiveFlag: createAction(ModuleEvents.GET_ACTIVE_FLAG, async() => {
    return await CommonRepository.getCurrencyShowHideData()
  }),
};



